import React, { useContext, useState, useEffect } from "react";
import {
  createCategory,
  getAllCategories,
  getCategoryById,
} from "../../api/functions/categories";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteCategoryModel from "./DeleteCategoryModel";
import showToast from "../common/showToast";
import { AppContext } from "../../context/AppContext";
import EditCategoryModel from "./EditCategoryModel";
import Downloads from "../common/downloads";

const CategoryForm = () => {
  const [categoryObject, setCategoryObject] = useState({});
  const [categoryImageName, setCategoryImageName] = useState(null);
  const [selectedCategoryFile, setSelectedCategoryFile] = useState(null);
  const [fetching, setFetching] = useState(true);

  const { categories, setCategories: setCategoriesContext } =
    useContext(AppContext);

  const [formCategory, setFormCategory] = useState({
    categoryName: "",
    categoryImage: null,
  });

  useEffect(() => {
    getAllCategoriesData();
  }, []);

  const handleReset = () => {
    setCategoryImageName(null);
    setSelectedCategoryFile(null);
    setFormCategory({
      categoryName: "",
      categoryImage: null,
    });
  };

  const handleInputChange = ({ target }) => {
    setFormCategory((prevState) => ({
      ...prevState,
      categoryName: target.value,
    }));
  };

  const handleFileChange = ({ target }) => {
    const file = target.files[0];

    if (file) {
      setSelectedCategoryFile(file);
      setCategoryImageName(file.name);

      setFormCategory((prevState) => ({
        ...prevState,
        categoryImage: file,
      }));
    }
  };

  const getAllCategoriesData = async () => {
    try {
      const response = await getAllCategories();

      if (response.status === 200) {
        setCategoriesContext(response.data.categories);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setFetching(false);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formCategory.categoryName);
    formDataToSend.append("image", formCategory.categoryImage);

    try {
      const response = await createCategory(formDataToSend);

      if (response.status === 201) {
        getAllCategoriesData();

        showToast(response.data.message.en, "info");
        handleReset();
      }
    } catch (err) {
      showToast(err.response?.data?.error?.message, "error");
      showToast(err.response?.data?.message, "error");
    }
  };

  const getCategoryObject = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getCategoryById(id);
      if (res.status === 200) {
        setCategoryObject(res.data.category);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  const customTableColumns = [
    "table-info",
    "table-warning",
    "table-danger",
    "table-success",
    "table-primary",
  ];

  const getTableRowColorClass = (index) => {
    const colorIndex = index % customTableColumns.length;
    return customTableColumns[colorIndex];
  };

  return (
    <>
      <DeleteCategoryModel
        category={categoryObject}
        fetchAllCategories={getAllCategoriesData}
      />
      <EditCategoryModel
        category={categoryObject}
        fetchAllCategories={getAllCategoriesData}
      />

      <form onSubmit={handleAddCategory} className="mb-4">
        <div className="form-group">
          {selectedCategoryFile ? (
            <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
              <img
                src={URL.createObjectURL(selectedCategoryFile)}
                alt="Selected"
                className="mt-2"
                style={{ maxWidth: "100%", maxHeight: "150px" }}
              />
              <input
                className="file-area__input"
                id="UploadCategoryImage"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          ) : (
            <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
              <label className="file-area__label" htmlFor="UploadCategoryImage">
                <input
                  className="file-area__input"
                  id="UploadCategoryImage"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <span className="uploader-product-media-file-area__label">
                  <svg
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                    className="uploader-product-media-file-area__icon uploader-product-media-file-area__icon_size_medium icon_picture-set icon_picture"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M3.098 10l-.815-3.333H37.71L36.892 10H3.098zm2.635-6.667L5.002 0h29.99l-.732 3.333H5.733zM40 30H0l3.333 10h33.334L40 30zM5.173 26.667l-1.356-10h32.351l-1.398 10h3.367L40 13.333H0l1.808 13.334h3.365zm4.64-6.659c-.081-.925.699-1.675 1.739-1.675 1.041 0 1.925.749 1.975 1.674.05.925-.73 1.675-1.74 1.675-1.009 0-1.894-.749-1.974-1.674zm12.625-.373l-3.04 4.467-3.021-2.187-4.71 4.752h16.666l-5.895-7.032z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  <strong className="t3">
                    {categoryImageName
                      ? categoryImageName
                      : "Upload presentational image"}
                  </strong>
                </span>
              </label>
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            type="text"
            name="name"
            required
            value={formCategory.categoryName || ""}
            className="form-control"
            placeholder="e.g., Salon"
            onChange={handleInputChange}
          />
        </div>

        <div className="d-flex align-items-center gap-3">
          <button type="submit" className="btn btn-primary">
            Add Category
          </button>
          <button type="reset" className="btn btn-light" onClick={handleReset}>
            Cancel
          </button>
        </div>
      </form>

      {fetching ? (
        <Downloads />
      ) : (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>delete</th>
              </tr>
            </thead>
            <tbody>
              {categories.length > 0 ? (
                categories?.map((category, index) => (
                  <tr
                    className={getTableRowColorClass(index)}
                    key={category?._id}
                  >
                    <td>{index + 1}</td>
                    <td>{category?.name?.en}</td>
                    <td>
                      <button
                        className="btn btn-success mr-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editCategory"
                        onClick={(e) => getCategoryObject(e, category._id)}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                      <button
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteCategory"
                        onClick={(e) => getCategoryObject(e, category._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>There's no categories added yet!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CategoryForm;
