import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const StarRating = ({ value, onChange }) => {
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <div>
      {stars.map((star) => (
        <FontAwesomeIcon
          key={star}
          icon={faStar}
          style={{
            color: star <= value ? "#FFC643" : "grey",
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={() => onChange(star)}
        />
      ))}
    </div>
  );
};

export default StarRating;
