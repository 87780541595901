import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/vertical-layout-light/style.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { AppContextProvider } from "./context/AppContext";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const driverObj = driver({
  showProgress: true,
  steps: [
    {
      element: ".page-header",
      popover: { title: "Title", description: "Description" },
    },
    {
      element: ".top-nav",
      popover: { title: "Title", description: "Description" },
    },
    {
      element: ".sidebar",
      popover: { title: "Title", description: "Description" },
    },
    {
      element: ".footer",
      popover: { title: "Title", description: "Description" },
    },
  ],
});

// driverObj.drive();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <App />
    <ToastContainer />
  </AppContextProvider>
);
