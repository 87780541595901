import React, { useContext, useEffect, useState } from "react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DeleteProduct from "./DeleteProduct";
import { getAllProducts, getProductById } from "../../api/functions/products";
import EditProductModel from "./EditProductModel";
import { AppContext } from "../../context/AppContext";
import { truncateText } from "../../utils/truncateText";
import { bufferToDataURL } from "../../utils/bufferToDataURL";
import showToast from "../common/showToast";
import Downloads from "../common/downloads";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const ProductsTable = () => {
  const { products, setProducts: setProductsContext } = useContext(AppContext);
  const [productObject, setProductObject] = useState();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    getAllProductsData();
  }, []);

  const getAllProductsData = async () => {
    try {
      const response = await getAllProducts();
      if (response.status === 200) {
        setProductsContext(response.data.products);
      }
    } catch (error) {
      showToast("Error fetching products", "error");
    } finally {
      setFetching(false);
    }
  };

  const getProductObject = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getProductById(id);
      if (res.status === 200) {
        setProductObject(res.data.product);
      }
    } catch (error) {
      showToast(error.response?.data?.message, "error");
    }
  };

  return (
    <>
      <DeleteProduct
        product={productObject}
        fetchProducts={getAllProductsData}
      />
      <EditProductModel
        product={productObject}
        fetchProducts={getAllProductsData}
      />

      {fetching ? (
        <Downloads />
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Colors</th>
              <th>Sizes</th>
              <th>Last updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product, index) => (
                <tr key={product._id}>
                  <td>{index + 1}</td>
                  <td>
                    {product?.title?.en}
                  </td>
                  <td>{product.price} DH</td>
                  <td>
                    {product.colors && JSON.parse(product.colors).map(color => color.label).join(", ")}
                  </td>
                  <td>
                    {product.sizes && JSON.parse(product.sizes).map(size => size.label).join(", ")}
                  </td>
                  <td>{moment(product?.updatedAt).fromNow()}</td>
                  <td>
                    <button
                      className="btn btn-success mr-2"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#editProduct"
                      onClick={(e) => getProductObject(e, product._id)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>

                    <button
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteProduct"
                      onClick={(e) => getProductObject(e, product._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">There's no products added yet!</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ProductsTable;
