import React from "react";
import showToast from "../common/showToast";
import { deleteOrderById } from "../../api/functions/sales";

const DeleteOrder = ({ orderObj, fetchOrders }) => {
  const handleDeleteOrder = async () => {
    try {
      const res = await deleteOrderById(orderObj.id);

      console.log("res.data => ", res.data);

      if (res.status === 200) {
        fetchOrders();
        showToast(res.data.message.en, "info");
      }
    } catch (error) {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="deleteSale"
      tabIndex="-1"
      aria-labelledby="deleteSaleLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteProductLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to delete the Order of{" "}
              <strong>{orderObj?.firstName}</strong> ?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteOrder}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteOrder;
