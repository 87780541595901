import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { getAllCategories } from "../../api/functions/categories";
import { createProduct } from "../../api/functions/products";
import showToast from "../common/showToast";
import uploadIcon from "../../assets/images/icons/upload.svg";
import Select, { components } from "react-select";

const { Option } = components;

const ColorOption = (props) => (
  <Option {...props}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: props.data.color || "transparent",
          marginRight: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      ></div>
      {props.label}
    </div>
  </Option>
);

const ProductForm = ({ fetchProducts }) => {
  const {
    categories,
    setCategories: setCategoriesContext,
    colorOptions,
  } = useContext(AppContext);

  const [imageName, setImageName] = useState(null);
  const [isfileImage, setFileImage] = useState(true);
  const [loading, setLoading] = useState(false);

  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
    categoryId: "",
    sizes: [],
    colors: [],
    brand: "",
    material: "",
    gender: "unisex",
    ageGroup: "",
    stockQuantity: 0,
    productImage: null,
    customColor: "",
    customSize: "",
    shipping: 0,
  });

  const [sizeOptions, setSizeOptions] = useState([
    { value: "xl", label: "xl" },
    { value: "l", label: "l" },
    { value: "xxl", label: "xxl" },
    { value: "s", label: "s" },
  ]);

  const getAllCategoriesData = async () => {
    try {
      const response = await getAllCategories();
      if (response.status === 200) {
        setCategoriesContext(response.data.categories);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAllCategoriesData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      const selectedFile = files[0];
      const imageType = /^image\//;

      if (!imageType.test(selectedFile?.type)) {
        setFileImage(false);
        setImageName("Please upload an image file.");
        return;
      }


      console.log("selectedFile => ", selectedFile);
      console.log("-----------------------");
      
      // Check if the image dimensions are less than 400x500px
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      
      console.log("img.src => ", URL.createObjectURL(selectedFile));

      img.onload = function () {
        if (this.width < 400 || this.height < 500) {
          setFileImage(false);
          setImageName("The image dimensions should be at least 400x500px.");
        } else {
          setFileImage(true);
          setImageName("The image has been uploaded successfully!");
          setSelectedImageFile(files);
          setFormData((prevState) => ({
            ...prevState,
            productImage: files,
          }));
        }
      };
    }
  };

  const onSubmitProduct = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("price", formData.price);
      formDataToSend.append("categoryId", formData.categoryId.value);
      formDataToSend.append("brand", formData.brand);
      formDataToSend.append("material", formData.material);
      formDataToSend.append("gender", formData.gender);
      formDataToSend.append("ageGroup", formData.ageGroup);
      formDataToSend.append("stockQuantity", formData.stockQuantity);
      formDataToSend.append("sizes", JSON.stringify(formData.sizes));
      formDataToSend.append("colors", JSON.stringify(formData.colors));
      formDataToSend.append("shipping", formData.shipping);

      if (formData.productImage) {
        for (let i = 0; i < formData.productImage.length; i++) {
          formDataToSend.append("images", formData.productImage[i]);
        }
      }

      const response = await createProduct(formDataToSend);

      if (response.status === 201) {
        fetchProducts();
        showToast(response.data.message.en, "success");
        resetFormData();
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleColorChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      colors: selectedOption,
    }));
  };

  const handleCustomColorChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      customColor: e.target.value,
    }));
  };

  const handleCustomColorAdd = () => {
    const newColor = formData.customColor.trim();
    if (newColor !== "") {
      setFormData((prevState) => ({
        ...prevState,
        colors: [...prevState.colors, { value: newColor, label: newColor }],
        customColor: "",
      }));
    }
  };

  const handleSizeChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      sizes: selectedOption,
    }));
  };

  const handleCustomSizeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      customSize: e.target.value,
    }));
  };

  const handleCustomSizeAdd = () => {
    const newSize = formData.customSize.trim();
    if (newSize !== "") {
      setFormData((prevState) => ({
        ...prevState,
        sizes: [...prevState.sizes, { value: newSize, label: newSize }],
        customSize: "",
      }));
    }
  };

  const resetFormData = () => {
    setImageName(null);
    setSelectedImageFile(null);
    setFormData({
      title: "",
      description: "",
      price: 0,
      categoryId: "",
      sizes: [],
      colors: [],
      brand: "",
      material: "",
      gender: "unisex",
      ageGroup: "",
      stockQuantity: 0,
      productImage: null,
      customColor: "",
      customSize: "",
      shipping: 0,
    });
  };

  return (
    <form onSubmit={onSubmitProduct} className="forms-sample">
      <div className="form-group">
        <label>Product Name</label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          maxLength={50}
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <textarea
          className="form-control"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows="4"
        ></textarea>
      </div>
      <div className="form-group">
        <label>Category</label>
       
        <Select
          value={formData.categoryId}
          onChange={(value) =>
            setFormData((prevState) => ({
              ...prevState,
              categoryId: value,
            }))
          }
          options={categories?.map((category) => ({
            value: category._id,
            label: category.name.en,
          }))}
          placeholder="Select Category"
        />
      </div>
      <div className="form-group">
        <label>Price</label>
        <input
          type="number"
          className="form-control"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          min={0}
          required
        />
      </div>

      <div className="form-group">
        <label>Sizes</label>
        <Select
          isMulti
          value={formData.sizes}
          onChange={handleSizeChange}
          options={sizeOptions}
          placeholder="Select or type to add"
        />
        <div className="mt-2">
          <input
            type="text"
            className="form-control"
            placeholder="Enter custom size"
            value={formData.customSize}
            onChange={handleCustomSizeChange}
          />
          <button
            type="button"
            className="btn btn-primary mt-2"
            onClick={handleCustomSizeAdd}
          >
            Add Custom Size
          </button>
        </div>
      </div>

      <div className="form-group">
        <label>Colors</label>
        <Select
          isMulti
          value={formData.colors}
          onChange={handleColorChange}
          options={colorOptions}
          placeholder="Select or type to add"
          components={{ Option: ColorOption }}
        />
        {/* <div className="mt-2">
          <input
            type="text"
            className="form-control"
            placeholder="Enter custom color"
            value={formData.customColor}
            onChange={handleCustomColorChange}
          />
          <button
            type="button"
            className="btn btn-primary mt-2"
            onClick={handleCustomColorAdd}
          >
            Add Custom Color
          </button>
        </div> */}
      </div>

      {/* Other form fields */}
      <div className="form-group">
        <label>Stock Quantity</label>
        <input
          type="number"
          className="form-control"
          name="stockQuantity"
          value={formData.stockQuantity}
          onChange={handleInputChange}
          min={0}
          required
        />
      </div>

      <div className="form-group">
        <label>shipping</label>
        <input
          type="number"
          className="form-control"
          name="shipping"
          value={formData.shipping}
          onChange={handleInputChange}
          min={0}
          required
        />
      </div>

      <div className="form-group">
        <div
          className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational"
          style={{ border: !isfileImage ? "2px solid #f00" : "none" }}
        >
          {selectedImageFile ? (
            <>
              <input
                className="file-area__input"
                type="file"
                accept="image"
                multiple
                id="uploader-product-presentational-media-input-1"
                onChange={handleFileChange}
              />
              <label
                className="file-area__label"
                htmlFor="uploader-product-presentational-media-input-1"
              >
                <span className="uploader-product-media-file-area__label">
                  {isfileImage && <img src={uploadIcon} alt="" />}
                  <strong className={`t3 ${isfileImage ? "" : "text-danger"}`}>
                    {imageName ? imageName : "Upload product images"}
                  </strong>
                </span>
              </label>
            </>
          ) : (
            <>
              <input
                className="file-area__input"
                type="file"
                accept="image"
                multiple
                id="uploader-product-presentational-media-input-1"
                onChange={handleFileChange}
              />
              <label
                className="file-area__label"
                htmlFor="uploader-product-presentational-media-input-1"
              >
                <span className="uploader-product-media-file-area__label">
                  {isfileImage && <img src={uploadIcon} alt="" />}
                  <strong className={`t3 ${isfileImage ? "" : "text-danger"}`}>
                    {imageName ? imageName : "Upload product images"}
                  </strong>
                </span>
              </label>
            </>
          )}
        </div>

        <div className="UploaderProductUploadSection__description t3 ">
          JPG, PNG formats only. File under 10MB. The main image must have an
          8:5 aspect ratio, the minimum size of 336 x 350 px.
        </div>
      </div>

      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? "Creating..." : "Create new product"}
      </button>
      <button
        type="button"
        className="btn btn-secondary ml-3"
        onClick={resetFormData}
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductForm;
