import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { updateUserInfo } from "../api/functions/auth";
import { AppContext } from "../context/AppContext";
import showToast from "../components/common/showToast";

const Profile = () => {
  const { userData } = useContext(AppContext);

  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: null,
    oldPassword: null,
    avatar: null,
  });

  useEffect(() => {
    setFormData({
      username: userData?.username || "",
      email: userData?.email || "",
      password: "",
      oldPassword: "",
      avatar: userData?.avatar,
    });
  }, [userData]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    if (name === "password" && !showOldPassword && value !== "") {
      setShowOldPassword(true);
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setSelectedImageFile(file);
    setFormData((prevState) => ({
      ...prevState,
      avatar: file,
    }));

    setShowModal(true);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      const previewImage = document.getElementById("modal-preview-image");
      if (previewImage) {
        previewImage.src = imageUrl;
      }
    }
  };

  const handleSaveUsername = async (e) => {
    e.preventDefault();
    setLoadingUsername(true);
    try {
      const res = await updateUserInfo({
        username: formData.username,
      });
      if (res.status === 200) {
        showToast(res.data.message.en, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setLoadingUsername(false);
    }
  };

  const handleSaveEmail = async (e) => {
    e.preventDefault();
    setLoadingEmail(true);
    try {
      const res = await updateUserInfo({
        email: formData.email,
      });
      if (res.status === 200) {
        showToast(res.data.message.en, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();
    setLoadingPassword(true);

    try {
      const res = await updateUserInfo({
        oldPassword: formData.oldPassword,
        password: formData.password,
      });
      if (res.status === 200) {
        showToast(res.data.message.en, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setLoadingPassword(false);
    }
  };

  return (
    <div id="profile">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <h3 className="pt-4 pl-4">Profile</h3>
            <div className="card-body">
              <form onSubmit={handleSaveUsername}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="username"
                      value={formData.username}
                      onChange={handleChanges}
                      required
                    />
                    <button
                      type="submit"
                      className="btn btn-primary ml-2"
                      disabled={loadingUsername}
                    >
                      {loadingUsername ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
              <form onSubmit={handleSaveEmail}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      value={formData.email}
                      onChange={handleChanges}
                      required
                    />
                    <button
                      type="submit"
                      className="btn btn-primary ml-2"
                      disabled={loadingEmail}
                    >
                      {loadingEmail ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
              <form onSubmit={handleSavePassword}>
                <div className="form-group">
                  <label htmlFor="oldPassword">Old Password</label>
                  <div className="input-group">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      className="form-control"
                      id="oldPassword"
                      value={formData.oldPassword}
                      onChange={handleChanges}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={toggleOldPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showOldPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="password">New Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      id="password"
                      value={formData.password}
                      onChange={handleChanges}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loadingPassword}
                >
                  {loadingPassword ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
