import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import api from "../api/http-service";
import Navbar from "../components/common/Navbar";
import Sidebar from "../components/common/Sidebar";

const DefaultLayout = () => {
  const { token, userData, removeToken } = useContext(AppContext);
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    if (token) {
      checkConnectionStatus();

      const interval = setInterval(() => {
        checkConnectionStatus();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [token]);

  const checkConnectionStatus = () => {
    fetch(api?.defaults?.baseURL)
      .then((response) => {
        if (!response.ok) {
          setIsConnected(false);
        } else {
          setIsConnected(true);
        }
      })
      .catch(() => {
        setIsConnected(false);
      });
  };

  if (!token) {
    removeToken();
    return <Navigate to="/account/login" replace />;
  }

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              {!isConnected && (
                <div className="alert alert-danger" role="alert">
                  Connection error! Please check your network connection.
                </div>
              )}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
