import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faGear,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../api/functions/auth";
import Downloads from "./downloads";
import { getWebsiteInfo } from "../../api/functions/websiteInfo";
import showToast from "./showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Navbar = () => {
  const {
    removeToken,
    userData,
    setUserData: setUserDataContext,
    websiteInfo,
    setWebsiteInfo: setWebsiteInfoContext,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [isShowProfileSettings, setIsShowProfileSettings] = useState(false);

  const handleClickProfile = () => {
    setIsShowProfileSettings(!isShowProfileSettings);
  };

  const ShowSidebarIcons = () => {
    document.querySelector("body").classList.toggle("sidebar-icon-only");
  };

  const showSidebar = () => {
    document.getElementById("sidebar").classList.toggle("active");
  };

  const handleRemoveToken = () => {
    removeToken();
    window.location.reload();
  };

  const websiteInfos = async () => {
    try {
      const res = await getWebsiteInfo();
      if (res.status === 200) {
        setWebsiteInfoContext(res.data.websiteInfo);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const [brandLogo, setBrandLogo] = useState("");
  const [miniLogo, setMiniLogo] = useState("");

  useEffect(() => {
    if (websiteInfo) {
      websiteInfo?.map((item) => {
        setBrandLogo(item.logoImage);
        setMiniLogo(item.miniLogoImage);
      });
    }
    websiteInfos();
  }, [websiteInfo]);

  const getUserData = async () => {
    try {
      const res = await getUserInfo();
      if (res.status === 200) {
        setUserDataContext({
          username: res.data.user.username,
          avatar: res.data.user.avatar,
        });
      }
    } catch (error) {
      // showToast(error?.response?.data?.message, "error");

      if (
        error?.response?.data?.message === "token expired, please login again"
      ) {
        handleRemoveToken();
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center ">
        <div className="navbar-brand-inner-wrapper gap-3 d-flex justify-content-between align-items-center w-100">
          {brandLogo && (
            <Link className="navbar-brand brand-logo" to="/">
              <img src={IMAGE_URL + "/image/" + brandLogo} alt="logo" />
            </Link>
          )}
          {miniLogo && (
            <Link className="navbar-brand brand-logo-mini" to="/">
              <img src={IMAGE_URL + "/image/" + miniLogo} alt="logo" />
            </Link>
          )}
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={ShowSidebarIcons}
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav mr-lg-2">
          <li className="nav-item nav-profile dropdown">
            <Link
              className="nav-link d-flex align-items-center gap-3"
              to="#"
              data-toggle="dropdown"
              id="profileDropdown"
              onClick={handleClickProfile}
            >
              <div className="user_icon">
                <FontAwesomeIcon icon={faUser} fontSize={18} color="#37465F" />
              </div>
              <span className="nav-profile-name">
                {userData?.username?.toUpperCase()}
              </span>
            </Link>

            <div
              className={`dropdown-menu dropdown-menu-right navbar-dropdown ${
                isShowProfileSettings ? "show" : ""
              }`}
              aria-labelledby="profileDropdown"
            >
              <Link className="dropdown-item" to="/profile">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-primary me-2 text-dark"
                />
                Profile
              </Link>
              <Link
                className="dropdown-item"
                href="#"
                onClick={handleRemoveToken}
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="text-primary me-2 text-dark"
                />
                Logout
              </Link>
            </div>
          </li>
        </ul>

        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={showSidebar}
        >
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
