import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { editPrivicy } from "../api/functions/conditions";
import showToast from "../components/common/showToast";

const Conditions = () => {
  const [contentPrivicy, setContentPrivicy] = useState("");
  const [contentTerms, setContentTerms] = useState("");

  const handleEditorChangeForPrivicy = (contentPrivicy) => {
    setContentPrivicy(contentPrivicy);
  };

  const handleEditorChangeForTerms = (contentTerms) => {
    setContentTerms(contentTerms);
  };

  const handlePrivicy = async (e) => {
    e.preventDefault();

    const dataToSend = {
      Pcontent: contentPrivicy,
    };

    try {
      const privicyRes = await editPrivicy(dataToSend);

      if (privicyRes.status === 200) {
        showToast(privicyRes?.data?.message?.en, "success");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTerms = async (e) => {
    e.preventDefault();

    const dataToSend = {
      Tcontent: contentTerms,
    };

    try {
      const termsRes = await editPrivicy(dataToSend);

      if (termsRes.status === 200) {
        showToast(termsRes?.data?.message?.en, "success");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-md-12 col-sm-12">
        <h2 className="mb-3">Privicy Policy</h2>
        <form onSubmit={handlePrivicy}>
          <SunEditor
            setOptions={{
              height: "600px",
              buttonList: [
                [
                  "font",
                  "align",
                  "fontSize",
                  "formatBlock",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "textStyle",
                  "list",
                  "link",
                  "fullScreen",
                  "print",
                ],
              ],
            }}
            onChange={handleEditorChangeForPrivicy}
            setContents={contentPrivicy}
          />

          <button type="submit" className="btn btn-primary mt-4">
            Save Privicy Policy
          </button>
        </form>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12">
        <h2 className="mb-3">Terms of used</h2>
        <form onSubmit={handleTerms}>
          <SunEditor
            setOptions={{
              height: "600px",
              buttonList: [
                [
                  "font",
                  "align",
                  "fontSize",
                  "formatBlock",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "textStyle",
                  "list",
                  "link",
                  "fullScreen",
                  "print",
                ],
              ],
            }}
            onChange={handleEditorChangeForTerms}
            setContents={contentTerms}
          />

          <button type="submit" className="btn btn-primary mt-4">
            Save Terms of used
          </button>
        </form>
      </div>
    </div>
  );
};

export default Conditions;
