import React, { createContext, useState, useEffect } from "react";
import { getAllProducts } from "../api/functions/products";
import { getAllCategories } from "../api/functions/categories";
import { getAllReviews } from "../api/functions/reviews";
import { getAllCompanies } from "../api/functions/company";
import { getAllSlideItems } from "../api/functions/heroSlider";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import { getWebsiteInfo } from "../api/functions/websiteInfo";
import { getUserInfo } from "../api/functions/auth";
import { getAllSales } from "../api/functions/sales";
import Loader from "../components/Loader/Loader.jsx";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userData, setUserData] = useState({});
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [websiteInfo, setWebsiteInfo] = useState([]);
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          productRes,
          categoryRes,
          reviewRes,
          companiesRes,
          slidersRes,
          portfolioRes,
          websiteInfoRes,
          userRes,
          salesRes,
        ] = await Promise.all([
          getAllProducts(),
          getAllCategories(),
          getAllReviews(),
          getAllCompanies(),
          getAllSlideItems(),
          getAllPortfolioImages(),
          getWebsiteInfo(),
          getUserInfo(),
          getAllSales(),
        ]);

        if (
          productRes.status === 200 &&
          categoryRes.status === 200 &&
          reviewRes.status === 200 &&
          companiesRes.status === 200 &&
          slidersRes.status === 200 &&
          portfolioRes.status === 200 &&
          websiteInfoRes.status === 200 &&
          userRes.status === 200 &&
          salesRes.status === 200
        ) {
          setProducts(productRes.data.products);
          setCategories(categoryRes.data.categories);
          setReviews(reviewRes.data.reviews);
          setCompanies(companiesRes.data.companies);
          setSliders(slidersRes.data.sliders);
          setPortfolioImages(portfolioRes.data.images);
          setWebsiteInfo(websiteInfoRes.data.websiteInfo);
          setUserData(userRes.data.user);
          setSales(salesRes.data.sales);
        }
      } catch (error) {
        console.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const setTokenAndStorage = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  const removeTokenAndStorage = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const [colorOptions] = useState([
    { value: "Red", label: "Red", color: "#f00" },
    { value: "Blue", label: "Blue", color: "#00f" },
    { value: "Green", label: "Green", color: "#0f0" },
    { value: "Yellow", label: "Yellow", color: "#ff0" },
    { value: "Black", label: "Black", color: "#000" },
    { value: "White", label: "White", color: "#fff" },
    { value: "Pink", label: "Pink", color: "#ffc0cb" },
    { value: "Purple", label: "Purple", color: "#800080" },
    { value: "Orange", label: "Orange", color: "#ffa500" },
    { value: "Gray", label: "Gray", color: "#808080" },
    { value: "Brown", label: "Brown", color: "#a52a2a" },
    { value: "Beige", label: "Beige", color: "#f5f5dc" },
    { value: "Navy", label: "Navy", color: "#000080" },
    { value: "Teal", label: "Teal", color: "#008080" },
    { value: "Magenta", label: "Magenta", color: "#ff00ff" },
    { value: "Turquoise", label: "Turquoise", color: "#40e0d0" },
    { value: "Lavender", label: "Lavender", color: "#e6e6fa" },
    { value: "Maroon", label: "Maroon", color: "#800000" },
    { value: "Cyan", label: "Cyan", color: "#00ffff" },
    { value: "Olive", label: "Olive", color: "#808000" },
    { value: "Indigo", label: "Indigo", color: "#4b0082" },
    { value: "Sapphire", label: "Sapphire", color: "#0f52ba" },
    { value: "Ruby", label: "Ruby", color: "#e0115f" },
    { value: "Emerald", label: "Emerald", color: "#50c878" },
    { value: "Pearl", label: "Pearl", color: "#eae0c8" },
    { value: "Gold", label: "Gold", color: "#ffd700" },
    { value: "Silver", label: "Silver", color: "#c0c0c0" },
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <AppContext.Provider
      value={{
        token,
        setToken: setTokenAndStorage,
        removeToken: removeTokenAndStorage,
        userData,
        setUserData,
        products,
        setProducts,
        categories,
        setCategories,
        reviews,
        setReviews,
        companies,
        setCompanies,
        sliders,
        setSliders,
        portfolioImages,
        setPortfolioImages,
        websiteInfo,
        setWebsiteInfo,
        sales,
        setSales,
        colorOptions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
