import React, { useState, useEffect } from "react";
import { editOrderById } from "../../api/functions/sales";
import showToast from "../common/showToast";

const EditOrderModel = ({ orderObj, fetchOrders }) => {
  const [formData, setFormData] = useState({
    productTitle: "",
    orderId: "",
    status: "",
  });

  useEffect(() => {
    setFormData({
      productTitle: orderObj?.product?.title?.en || "",
      orderId: orderObj?.id || "",
      status: orderObj?.status || "",
    });

    console.log("formData => ", orderObj);
  }, [orderObj]);

  const handleEditOrder = async (e) => {
    e.preventDefault();

    console.log("formData => ", formData);

    try {
      const res = await editOrderById(orderObj?.id, formData);
      if (res.status === 200) {
        fetchOrders();
        showToast("Order has been updated successfully", "info");
      }
    } catch (error) {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "shipping":
        return "yellow";
      case "delivered":
        return "green";
      case "canceled":
        return "red";
      default:
        return "";
    }
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case "shipping":
        return "black";
      case "delivered":
        return "white";
      case "canceled":
        return "white";
      default:
        return "black";
    }
  };

  return (
    <div
      className="modal fade"
      id="editSale"
      tabIndex="-1"
      aria-labelledby="editSaleLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteProductLabel">
              Edit Order with ID {orderObj?.id}
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleEditOrder} className="forms-sample">
              <div className="modal-body">
                <div className="form-group">
                  <label>Product Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.productTitle}
                    onChange={handleInputChange}
                    required
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label>Order ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.orderId}
                    onChange={handleInputChange}
                    required
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    style={{
                      color: getStatusTextColor(formData.status),
                      backgroundColor: getStatusColor(formData.status),
                    }}
                  >
                    {["shipping", "delivered", "canceled"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Update product
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrderModel;
