import React, { useContext, useEffect, useState } from "react";
import { updateProduct } from "../../api/functions/products";
import showToast from "../common/showToast";
import { AppContext } from "../../context/AppContext";
import Select from "react-select";
import uploadIcon from "../../assets/images/icons/upload.svg";

const EditProductModel = ({ product, fetchProducts }) => {
  const { categories, colorOptions } = useContext(AppContext);

  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [isfileImage, setFileImage] = useState(true);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
    categoryId: "",
    colors: [],
    sizes: [],
    stockQuantity: 0,
    productImage: null,
  });

  const [sizeOptions, setSizeOptions] = useState([
    { value: "xl", label: "xl" },
    { value: "l", label: "l" },
    { value: "xxl", label: "xxl" },
    { value: "s", label: "s" },
  ]);

  const handleCustomSizeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      customSize: e.target.value,
    }));
  };

  const handleCustomColorAdd = () => {
    const newColor = formData.customColor.trim();
    if (newColor !== "") {
      setFormData((prevState) => ({
        ...prevState,
        colors: [...prevState.colors, { value: newColor, label: newColor }],
        customColor: "",
      }));
    }
  };

  const handleCustomSizeAdd = () => {
    const newSize = formData.customSize.trim();
    if (newSize !== "") {
      setFormData((prevState) => ({
        ...prevState,
        sizes: [...prevState.sizes, { value: newSize, label: newSize }],
        customSize: "",
      }));
    }
  };

  const handleCustomColorChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      customColor: e.target.value,
    }));
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      const selectedFile = files[0];
      const imageType = /^image\//;

      if (!imageType.test(selectedFile?.type)) {
        setFileImage(false);
        setImageName("Please upload an image file.");
        return;
      }

      setFileImage(true);

      setSelectedImageFile(files);
      setImageName("The image has been uploaded successfully!");

      console.log("imageName => ", imageName);

      setFormData((prevState) => ({
        ...prevState,
        productImage: files,
      }));
    }
  };

  useEffect(() => {
    if (product) {
      setFormData({
        title: product?.title.en || "",
        description: product?.description.en || "",
        price: product?.price || 0,
        categoryId: product?.categoryId || "",
        colors: JSON.parse(product?.colors) || [],
        sizes: JSON.parse(product?.sizes) || [],
        productImage: null,
      });
    }
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditProduct = async (e, id) => {
    e.preventDefault();

    console.log("formData => ", formData);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("price", formData.price);
      formDataToSend.append("categoryId", formData.categoryId);
      // formDataToSend.append("brand", formData.brand);
      // formDataToSend.append("material", formData.material);
      // formDataToSend.append("gender", formData.gender);
      // formDataToSend.append("ageGroup", formData.ageGroup);
      // formDataToSend.append("stockQuantity", formData.stockQuantity);
      formDataToSend.append("sizes", JSON.stringify(formData.sizes));
      formDataToSend.append("colors", JSON.stringify(formData.colors));

      // console.log("formData.categoryId.value => ", formData.categoryId);

      if (formData.productImage) {
        for (let i = 0; i < formData.productImage.length; i++) {
          formDataToSend.append("images", formData.productImage[i]);
        }
      }

      const response = await updateProduct(id, formDataToSend);
      console.log("response => ", response);

      if (response.status === 200) {
        fetchProducts();
        showToast(response.data.message.en, "success");
      }
    } catch (error) {
      console.log("Error: ", error);

      showToast(error?.response?.data?.message, "error");
    }
  };

  const handleColorChange = (selectedOptions) => {
    setFormData((prevState) => ({
      ...prevState,
      colors: selectedOptions,
    }));
  };

  const handleSizeChange = (selectedOptions) => {
    setFormData((prevState) => ({
      ...prevState,
      sizes: selectedOptions,
    }));
  };

  const customColorStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
    }),
  };

  return (
    <div
      className="modal fade"
      id="editProduct"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editProductLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editProductLabel">
              Edit product {product?.title.en}
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form
            onSubmit={(e) => handleEditProduct(e, product?._id)}
            className="forms-sample"
          >
            <div className="modal-body">
              <div className="form-group">
                <label>Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  maxLength={30}
                  required
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows="4"
                ></textarea>
              </div>

              <div className="form-group">
                <label>Category</label>
                <select
                  className="form-control"
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name.en}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Stock Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  name="stockQuantity"
                  value={formData.stockQuantity}
                  onChange={handleInputChange}
                  min={0}
                  required
                />
              </div>

              <div className="form-group">
                <label>Price</label>
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Colors</label>
                <Select
                  isMulti
                  value={formData.colors}
                  onChange={handleColorChange}
                  options={colorOptions}
                  styles={customColorStyles}
                />
                {/* <div className="mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter custom color"
                    value={formData.customColor}
                    onChange={handleCustomColorChange}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={handleCustomColorAdd}
                  >
                    Add Custom Color
                  </button>
                </div> */}
              </div>

              <div className="form-group">
                <label>Sizes</label>
                <Select
                  isMulti
                  value={formData.sizes}
                  onChange={handleSizeChange}
                  options={sizeOptions}
                  styles={customColorStyles}
                />
                <div className="mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter custom size"
                    value={formData.customSize}
                    onChange={handleCustomSizeChange}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={handleCustomSizeAdd}
                  >
                    Add Custom Size
                  </button>
                </div>
              </div>

              <div className="form-group">
                <div
                  className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational"
                  style={{ border: !isfileImage ? "2px solid #f00" : "none" }}
                >
                  {selectedImageFile ? (
                    <>
                      <input
                        className="file-area__input"
                        type="file"
                        accept="image"
                        multiple
                        id="uploadEditProductImage"
                        onChange={handleFileChange}
                      />
                      <label
                        className="file-area__label"
                        htmlFor="uploadEditProductImage"
                      >
                        <span className="uploader-product-media-file-area__label">
                          {isfileImage && <img src={uploadIcon} alt="" />}
                          <strong
                            className={`t3 ${isfileImage ? "" : "text-danger"}`}
                          >
                            {imageName ? imageName : "Upload product images"}
                          </strong>
                        </span>
                      </label>
                    </>
                  ) : (
                    <>
                      <input
                        className="file-area__input"
                        type="file"
                        accept="image"
                        multiple
                        id="uploadEditProductImage"
                        onChange={handleFileChange}
                      />
                      <label
                        className="file-area__label"
                        htmlFor="uploadEditProductImage"
                      >
                        <span className="uploader-product-media-file-area__label">
                          {isfileImage && <img src={uploadIcon} alt="" />}
                          <strong
                            className={`t3 ${isfileImage ? "" : "text-danger"}`}
                          >
                            {imageName ? imageName : "Upload product images"}
                          </strong>
                        </span>
                      </label>
                    </>
                  )}
                </div>

                <div className="UploaderProductUploadSection__description t3 ">
                  JPG, PNG formats only. File under 10MB. The main image must
                  have an 8:5 aspect ratio, the minimum size of 336 x 350 px.
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Update Product
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProductModel;
