import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { getAllSales, getSaleById } from "../api/functions/sales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditOrderModel from "../components/Order/EditOrderModel";
import DeleteOrder from "../components/Order/DeleteOrder";

const SalesPage = () => {
  const { sales, setSales: setSalesContext } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleObject, setSaleObj] = useState(null);

  const getAllSalesData = async () => {
    try {
      const res = await getAllSales();
      if (res.status === 200) {
        setSalesContext(res.data.sales);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSalesData();
  }, []);

  const getSaleObject = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getSaleById(id);
      if (res.status === 200) {
        setSaleObj(res.data.sale);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DeleteOrder orderObj={saleObject} fetchOrders={getAllSalesData} />
      <EditOrderModel orderObj={saleObject} fetchOrders={getAllSalesData} />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Client Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Product Title</th>
                      <th>Product ID</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sales.length > 0 ? (
                      sales.map((order) => (
                        <tr key={order?.id}>
                          <td>{order?.id}</td>
                          <td class="text-capitalize">{order?.firstName}</td>
                          <td class="text-capitalize">{order?.address}</td>
                          <td class="text-capitalize">+{order?.phone}</td>
                          <td class="text-capitalize">
                            {order?.product?.title?.en}
                          </td>
                          <td>{order?.product?.id}</td>
                          <td class="text-capitalize">
                            <span className={`status_style ${order?.status}`}>
                              {order?.status}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-success mr-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editSale"
                              onClick={(e) => getSaleObject(e, order?.id)}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>

                            <button
                              className="btn btn-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteSale"
                              onClick={(e) => getSaleObject(e, order?.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="8" className="text-center">
                          There's no order yet!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesPage;
