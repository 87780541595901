import React, { useState } from "react";
import showToast from "../common/showToast";
import { createReview } from "../../api/functions/reviews";
import StarRating from "./StarRating";

const ReviewForm = ({ fetchReviews }) => {
  const [formData, setFormData] = useState({
    clientName: "",
    description: "",
    stars: 5,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createReview(formData);
      if (response.data) {
        fetchReviews();
        showToast(response.data.message.en, "info");
        setFormData({
          clientName: "",
          description: "",
          stars: 3,
        });
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="forms-sample">
      <div className="form-group">
        <StarRating
          value={formData.stars}
          onChange={(stars) => setFormData({ ...formData, stars })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="companyName">Client name</label>
        <input
          className="form-control"
          placeholder="eg. Jhon Doe"
          type="text"
          id="companyName"
          name="companyName"
          value={formData.clientName || ""}
          onChange={(e) =>
            setFormData({ ...formData, clientName: e.target.value })
          }
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="companyContent">Description</label>
        <textarea
          id="companyContent"
          rows="4"
          className="form-control"
          placeholder="eg. I would like to thank..."
          value={formData.description || ""}
          onChange={(e) =>
            setFormData({
              ...formData,
              description: e.target.value,
            })
          }
          required
        ></textarea>
      </div>
      <button type="submit" className="btn btn-primary mr-2">
        Submit
      </button>
      <button className="btn btn-light">Cancel</button>
    </form>
  );
};

export default ReviewForm;
